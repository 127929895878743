const validEmail = (email) => {
    // 使用正则表达式检测电子邮件地址的格式
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
}

const validValue = (value) => {
    if (value === null || value === undefined || value === "") {
        return false
    }
    return true
}

export { validEmail, validValue }
