// cookieService.js
import Cookies from "js-cookie"

// 设置 cookie
export function setCookie(key, value, options) {
    Cookies.set(key, value, options)
}

// 获取 cookie
export function getCookie(key) {
    return Cookies.get(key)
}

// 删除 cookie
export function removeCookie(key) {
    Cookies.remove(key)
}
