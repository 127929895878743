const tabbarStore = {
    state: {
        index: 0, // tabbar 索引
    },

    getters: {
        getTabbarIndex(state) {
            return state.index
        }
    },

    mutations: {
        setTabbarIndex(state, index) {
            state.index = index
        }
    }
}


export default tabbarStore
