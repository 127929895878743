import { Store } from 'vuex'
import tabbarStore from './modules/tabbarStore'
import cartStore from './modules/cartStore'
import userStore from './modules/userStore'
import pageStore from './modules/pageStore'
import screenStore from './modules/screenStore'


const store = new Store({
    modules: {
        tabbarStore,
        cartStore,
        userStore,
        pageStore,
        screenStore
    },
    state: {
    },
    mutations: {
    }
})


export default store
