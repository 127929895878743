import { showNotify } from "vant"


/**
 * Display a success notification with the given message for a specified duration.
 *
 * @param {string} message - the message to be displayed in the notification
 * @param {number} duration - the duration for which the notification will be displayed (default is 500)
 */
export function cNotiSuccess(message, duration=500) {
    showNotify({
        type: "success",
        message: message,
        duration: duration
    })
}

/**
 * Displays a danger notification message for a specified duration.
 *
 * @param {string} message - The message to be displayed in the notification.
 * @param {number} duration - The duration for which the notification should be displayed, default is 500ms.
 * @return {void}
 */
export function cNotiError(message, duration=1000) {
    showNotify({
        type: "danger",
        message: message,
        duration: duration
    })
}
