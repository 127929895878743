import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/i18n'
import vant from 'vant'
import 'vant/lib/index.css';
import { Lazyload } from 'vant';


const app = createApp(App)
app.use(Lazyload)

// 引入vant components
const vantComponents = [
    Lazyload
]
vantComponents.forEach(component => {
    app.use(component)
})

// 全局注册Vant
app.use(vant)
// 引入store
app.use(store)
// 引入i18n
app.use(i18n)
// 引入router
app.use(router)
// 挂载
app.mount('#app')
