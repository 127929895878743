import { get, post } from '@/utils/request.js'
import { h5CustomerApi } from '../base/urlConfig.js'


export function CustomerLoginService(data) {
    var url = h5CustomerApi.CustomerLogin
    return post(url, data, true)
}


export function CustomerRegisterService(data) {
    var url = h5CustomerApi.CustomerRegister
    return post(url, data, true)
}


export function FetchCustomerInfoService(token) {
    var url = h5CustomerApi.FetchUserInfo
    let params = { token: token }
    return post(url, params, true)
}
