const screenStore = {
    state: {
        isLoading   : false,   // loading indicator
        isFullScreen: false,   // full screen indicator
    },
    getters: {
        /**
         * Check if the state is currently loading.
         *
         * @param {object} state - the state object
         * @return {boolean} the loading status
         */
        isLoading: (state) => {
            return state.isLoading
        },
        /**
         * Check if the state is in full screen mode.
         *
         * @param {Object} state - the state object
         * @return {boolean} the value indicating if the state is in full screen mode
         */
        isFullScreen: (state) => {
            return state.isFullScreen
        }
    },
    mutations: {
        /**
         * showLoading - A function to set the isLoading state to true.
         *
         * @param {object} state - The state object
         */
        showLoading(state) {
            state.isLoading = true
        },
        /**
         * Dismisses the loading state.
         *
         * @param {object} state - the state object
         * @return {void}
         */
        dismissLoading(state) {
            state.isLoading = false
        },
        /**
         * Set the full screen state.
         *
         * @param {type} state - description of state parameter
         * @param {type} flag - description of flag parameter
         * @return {type}
         */
        setFullScreen(state, flag) {
            state.isFullScreen = flag
        },
    }
}


export default screenStore
